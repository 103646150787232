import React from 'react';
import '../../Styles.css';

const Footer = (props) => {
    return (
        <>
            <footer>
                <p className="copyright">&copy;2019 James Lyons</p>
            </footer>
        </>
    );
};

export default Footer;